#chat__container,.banner_pc,.in-product-spec {
  display: none;
}
#launcher {
  bottom: 5rem !important;
}
#live-container {
  #chat__container,#video__container {
    min-height: 600px;
  }
  iframe {
    width: 100%;
  }
}
.IN-iconfont(@code:"") {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: @code;
  font-family: "iconFont" !important;
  font-size: 2.4rem;
  font-style: normal;
}
.accordion-active .icon-arrow-down {
  display: inline-block;
  transform: rotate(180deg);
  transition: all 0.25s ease;
}
.activity-coupon {
  color: red;
  font-weight: bold;
}
.article {
  &__info {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__title {
    font-size: 22px;
    line-height: 1.4;
  }
}
.badge {
  display: inline-block;
  padding: 3px 0;
  img {
    margin-right: 10px;
    width: 20px;
  }
}
.badge__more {
  color: #ff9017;
  cursor: pointer;
  display:inline-block;
}
.banner__container {
  margin-top: 20px;
}
.between {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.bill-info .bill-info-table li {
  padding: 6px 0;
}
.bill-info-table {
  margin: 10px 0;
  li {
    margin-bottom: 10px;
  }
}
.bill-order-list {
  display: block;
  padding: 0 0 6px;
}
.bind-tips .cancel {
  background: #ff3200;
}
.email-account {
  color: #999 !important;
}
.emoji {
  width: 23px!important;
}
.err-msg {
  color: red;
  font-size: 14px !important;
}
.error-tips {
  color: red;
  line-height: 1.2;
  padding: 20px;
}
.fade-enter,.fade-leave-to,.picker.picker-fade-enter {
  opacity: 0;
}
.fade-enter-active,.fade-leave-active {
  transition: opacity 0.5s;
}
.fans-page {
  .in-ads-title {
    display: none;
  }
  .in-slider-header-container {
    background: #f9f9f9;
    top: 4.5rem;
  }
}
.fans-share-btn {
  margin-top: 0.8rem;
}
.final-price .price {
  color: #000;
}
.flex {
  display: flex;
  margin: 20px 0;
  &.split & > div {
    flex: 1;
    padding: 0 20px;
    + div {
      border-left: 1px solid #e3e3e3;
    }
  }
}
.flex-between {
  align-items: center !important;
  display: flex !important;
  justify-content: flex-end !important;
}
.form-item {
  margin-bottom: 12px;
  .in-input-row {
    margin: 0.8rem 0;
  }
  label {
    color: #666;
    display: block;
    // font-size: 24px;
    margin-bottom: 12px;
  }
}
.form__title {
  font-size: 24px;
  margin-bottom: 20px;
  padding: 10px 0;
}
.hide {
  display: none !important;
}
.in-add-address-container .v--modal {
  border-radius: 0;
}
.in-checkbox-col {
  .centered-line(2rem);
  align-items: center;
  display: flex;
  label {
    .padding-left-rem(10px);
  }
}
.in-city,.in-state & {
  .in-address-select {
    top: 0;
  }
  .swiper-container {
    height: 260px;
    width: 100%;
    & .swiper-slide {
      height: auto;
    }
  }
}
.in-container {
  .est-layout-page(100%);
  .confirm-container {
    padding: 40px 25px;
    p.msg {
      font-size: 1.5rem;
      line-height: 2rem;
      text-indent: 2rem;
    }
  }
  .in-btn .confirm-cancel {
    background: #666;
  }
}
.in-country .swiper-container {
  height: 26px;
  width: 100%;
  & .swiper-slide {
    height: auto;
  }
}
.in-country-open .in-choose-country {
  transform: translateX(0);
}
.in-error,.in-info,.in-success {
  &.in-register-info a {
    color: @green;
  }
  .in-modal p {
    .font-size-rem(20px);
    .margin-rem(@spacing-fourfold);
    color: #fff;
    line-height: 1.5em;
    text-align: center;
  }
  .v--modal {
    background-color: rgba(0, 0, 0, .7);
  }
  .v--modal-overlay {
    background: none;
  }
}
.in-error.black .v--modal {
  background-color: rgba(0, 0, 0, 0.7);
}
.in-form {
  align-content: space-between;
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 100%;
  }
}
.in-form-group {
  .margin-rem(@spacing-fourfold 0);
}
.in-index-content .in-slider-container {
  top: 7.8rem;
}
.in-info-row.input-icon-right {
  position: relative;
  .icon-copy {
    bottom: 0;
    margin: auto;
    position: absolute;
    right: 10px;
    top: 0;
  }
  input {
    padding-right: 40px;
  }
}
.in-load-more {
  .padding-bottom-rem(20px);
  display: flex;
  &:before {
    background: url("../images/spinner.svg") no-repeat center/cover;
    content: '';
    display: block;
    height: 3rem;
    margin: auto;
    width: 3rem;
  }
}
.in-loading {
  background: rgba(255, 255, 255, .6);
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 6666;
  &:before {
    background: url("../images/spinner.svg") no-repeat center/cover;
    content: "";
    display: block;
    height: 3rem;
    margin: auto;
    width: 3rem;
  }
}
.in-nav,.in-quick-wrapper i {
  .font-size-rem(48px);
}
.in-no-login a {
  color: @green;
}
.in-payment-item .in-checkbox-input {
  margin-right: 10px;
}
.in-pic-preview {
  .swiper-slide {
    display: flex;
    .big-img-preview {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #000;
      img {
        height: auto;
        max-width: 100%;
        width: auto;
        max-height: 100%;
      }
    }
  }
  .swiper-wrapper {
    height: 100vw;
  }
  .v--modal-overlay {
    background: rgba(0, 0, 0, 1);
    .v--modal {
      background-color: #000;
    }
  }
}
.in-pp-inventory .in-account-nav h2 + a {
  opacity: 1!important;
  pointer-events: auto!important;
}
.in-share__phone {
  margin: 20px 0 0;
  img:not[src] {
    visibility: hidden;
  }
  li {
    border-radius: 5px;
    border: 2px solid #ccc;
    cursor: pointer;
    display: inline-block;
    height: 100px;
    margin-bottom: 10px;
    margin-right: 10px;
    max-width: 100%;
    width: 100px;
    &.active {
      border-color: @btn-base-color;
    }
  }
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.in-side-menu {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  .swiper-container {
    height: 100%;
  }
}
.in-static-page-content {
  margin-bottom: 7rem;
  margin-top: 5.6rem;
}
.in-tab-controller {
  .centered-line(2.5rem);
  display: flex;
  margin-top: -2.5rem;
  a {
    .font-size-rem(24px);
    color: #fff;
    flex: 1;
    text-align: center;
    text-transform: uppercase;
    &.active {
      border-bottom: 5px solid @green;
    }
  }
}
.in-to-login {
  .margin-rem(@spacing-fourfold 0);
  color: @light-gray;
  text-align: center;
  a {
    .font-size-rem(14px);
    color: #000;
    &.phone {
      .font-size-rem(24px);
      .inline-block();
      .margin-rem(20px 0);
      color: @font-color;
      font-style: italic;
    }
  }
  p {
    .font-size-rem(16px);
    line-height: 1.625em;
  }
}
.in-toggle {
  .margin-rem(@spacing-fourfold 0);
  text-align: center;
  span {
    color: #000;
  }
}
.in-top-banner {
  a {
    .size(100%, 47vw);
    display: flex;
  }
  img {
    .size(100%, 100%);
    margin: auto;
    &[lazy=loading] {
      .size(3rem, 3rem);
    }
  }
}
.in-view-more {
  cursor: pointer;
  margin: 15px 0;
  text-align: center;
  &.active .icon-arrow-down {
    transform-origin: center;
    transform: rotate(180deg);
  }
  .icon-arrow-down {
    display: inline-block;
    transition: all .5s ease;
    vertical-align: middle;
  }
  span {
    .font-size-rem(14);
    color: #666666;
  }
}
.in-warehouse {
  padding: 1rem .8rem;
}
.info__list {
  font-size: 20px;
  line-height: 1.8;
  dd {
    font-size: 20px;
    margin-left: 20px;
  }
}
.input-select {
  background: transparent;
  font-size: 100%;
}
.mode-translate-fade-enter {
  opacity: 0;
  transform: translateX(-50%);
}
.mode-translate-fade-enter-active {
  transition: all .3s ease-in-out;
}
.mode-translate-fade-leave-active {
  opacity: 0;
  transform: translateX(-50%);
  transition: all .3s ease-in-out;
}
.no-data {
  font-size: 16px;
  margin: 2rem;
  text-align: center;
}
.no-payment-tips {
  font-size: 1rem;
  line-height: 1.2;
  padding: 16px 0;
}
.no-results {
  color: #A8A8A8;
  font-size: 22px;
  margin: 20px 0;
  text-align: center;
}
.o-hidden,.ophide {
  opacity: 0;
  pointer-events: none;
}
.order-view-tick-msg {
  color: #ff3200;
  padding-top: 0.8rem;
}
.page_content {
  height: 100vh;
  overflow-y: scroll;
  .standby {
    height: 2.4rem;
    width: 2.4rem;
  }
}
.paid-text {
  float: right;
}
.payment-desc {
  .font-size-rem(12);
  color: #a1a1a1;
  line-height: 1.6;
  padding-top: 10px;
}
.pick-up__result .modal__container {
  min-height: 300px;
}
.pop-out-enter {
  opacity: 0;
  transform: translateY(100%);
}
.pop-out-enter-active {
  transition: all 0.5s;
}
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.5s;
}
.preview-img__box {
  margin: 20px 0;
  img {
    border-radius: 5px;
    border: 2px solid #ccc;
    max-width: 100px;
  }
}
.primary-color {
  color: #333;
}
.product-option {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  margin-left: -10px;
}
.register-linked {
  word-break: break-word;
}
.rest-password__notice {
  color: #c2c2c2 !important;
  font-size: 1.2rem;
  margin: 0.4rem 0 0;
  a {
    color: #ff6b00;
    font-size: 1.2rem;
  }
}
.rma-action {
  justify-content: flex-end !important;
}
.split-line {
  display: flex;
  flex-wrap: wrap;
  .comment__text {
    display: inline-block;
    font-size: 16px;
    line-height: 1.4;
    margin: 10px 0;
    + .comment__text span {
      border-left: 1px solid #666;
    }
    span {
      display: inline-block;
      line-height: 1;
      padding: 0 8px;
    }
  }
  .comment__text:first-child {
    margin-left: -8px;
  }
}
.status-qty {
  border-right: 1px solid #666;
  color: #666;
  display: block;
  margin: 5px 0;
  padding: 0 10px;
  & + & {
    margin-top: 4px;
  }
  &:last-child {
    border: none;
  }
}
.stc-item {
  .padding-rem(@spacing-fourfold);
  margin-top: 2.8rem;
  .title {
    .font-size-rem(24px);
    .margin-rem(20px 0);
  }
  a {
    color: #ff3200;
  }
  p {
    color: @gray;
    line-height: 1.6em;
  }
}
.telephone {
  color: #999;
}
.title-line {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;
  padding: 15px 0;
  text-align: center;
  h2 {
    color: #333;
    font-size: 1.4rem;
    font-weight: 400;
  }
}
.unbind-container {
  padding: 10px 0;
  & + & {
    border-top: 1px solid #ccc;
    padding: 30px 0;
  }
  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.unified-revision {
  border-bottom: 1px solid #979797;
  margin-bottom: .8rem;
  .in-cart-bar {
    position: static;
  }
}
.used-coupon-card .points__item {
  border: 2px solid #ff6b00;
  cursor: initial;
  position: relative;
  .icon-plus {
    color: #333;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(45deg);
    width: auto;
  }
}
.vdp-datepicker {
  width: 100%;
  input {
    width: 100%;
  }
}
.vdp-datepicker__calendar {
  position: inherit !important;
  width: 100% !important;
  header {
    display: block !important;
  }
}
.video-container {
  margin: 10px 0;
  .video-js {
    height: 350px;
    width: 100%;
  }
}
.xclub-comment .no-data {
  border-top: 1px solid #666;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 1rem;
}
.xclubEvaluation {
  position: relative;
  &::before {
    background-image: url("../images/XClub-Unboxing.png");    background-repeat: no-repeat;    background-size: cover;    background-position: center;    position: absolute;    top: -27px;
    content: "";
    display: inline-block;
    height: 55px;
    width: 260px;
  }
}
.zopim[data-test-id="ChatWidgetMobileButton"] {
  display: none!important;
}
@keyframes slideInLeft {
  from {
    transform: translate3d(-50%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideOutLeft {
  from {
    transform: translate3d(50%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
