.checkout-index {
  .in-cart-bar {
    height: 4rem;
    line-height: 4rem;
  }
  .in-checkout-content {
    background: none;
    box-shadow: none;
    padding: 0.8rem 0.8rem 0;
    & + .in-checkout-totals {
      display: none;
    }
    .in-shipping-address {
      padding: 0;
      h2 {
        color: #aaaaaa;
        font-size: 1rem;
        margin: 0.2rem 0 0.8rem 0;
        text-align: left;
      }
      ul li {
        background: white;
        border-radius: 0.3rem;
        border: 0.1rem solid white;
        box-shadow: 0 0.05rem 0.7rem 0 rgba(0, 0, 0, 0.06);
        &.active {
          // background: #ffebdc;
          // border: 0.1rem solid @c-orange;
          background: #e3e3e3;
          border: 0.1rem solid #000;
        }
        .in-edit &:before {
          .IN-iconfont("\e6fb");              color: #666666;
        }
        .in-tel {
          color: #666666;
        }
        .item_address {
          font-size: 1.2rem;
          margin-bottom: 0.4rem;
        }
        .item_name {
          font-size: 1.3rem;
        }
      }
    }
    .in-shipping-information {
      background: white;
      border-radius: 0.2rem;
      padding: 0.8rem;
      .in-input-row {
        border-radius: 1.5rem;
        font-size: 1.4rem;
        height: 3rem;
        line-height: 3rem;
        textarea {
          .padding-rem(10rem @spacing-fourfold);
          background-color: #eee;
          border-radius: 1.5rem;
          border: none;
          font-size: 100%;
          outline: none;
        }
      }
      .in-input-row:last-child {
        height: auto;
      }
    }
    .in-shipping-method {
      background: white;
      border-radius: 0.3rem;
      box-shadow: 0 0.05rem 0.7rem 0 rgba(0, 0, 0, 0.06);
      margin-bottom: 0.8rem;
      h2 {
        border-bottom: 1px solid gainsboro;
        color: #aaaaaa;
        font-size: 1rem;
        height: 2.9rem;
        line-height: 2.9rem;
        margin: 0;
        text-align: left;
      }
      ul {
        padding: 0.4rem 0;
        li.in-method-item {
          background: white;
          border: none;
          margin: 0;
          padding-left: 2.8rem;
          &:before {
            .IN-iconfont("\e718");            color: #666666;            background: none;            left: 0;
          }
          .in-title {
            font-size: 1.3rem;
          }
          .price {
            color: #666666;
            font-size: 1.1rem;
          }
        }
      }
    }

    .points-container {
      margin-bottom: 10px;
      padding-bottom: 0;
      border-top: none;
      box-shadow: 0 0.05rem 0.7rem 0 rgba(0, 0, 0, 0.06);

      .title {
        height: auto;
        margin: 0;
        padding: 15px 0 10px;
        font-size: 14px;
        color: #4B4B4B;
        text-align: left;
        text-transform: capitalize;
      }

      .points-info {
        display: flex;
        align-items: center;
        padding: 0 20px 10px 0;
        border-bottom: 1px solid #E3E3E2;

        .check-icon {
          flex: none;
          width: 26px;
          height: 26px;
          margin-right: 10px;
          border-radius: 50%;
          background: url("../images/no-tick.png") no-repeat center/contain;

          &.active {
            background: url("../images/tick.png") no-repeat center/contain;
          }
        }

        .detail {
          font-size: 12px;
          color: #333;
          line-height: 17px;
        }
      }
      .points-select {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #000;
        padding: 10px 0 15px 36px;

        .select-btn {
          display: flex;
          align-items: center;
          margin-left: 10px;
          color: #000;
          padding: 6px 10px;
          background-color: #F6F6F6;
          border: 1px solid #DBDBDB;

          .link {
            margin-left: 21px;

            i {
              color: #000;
              font-size: 16px;
              margin: 0;
            }
          }
        }
      }

      .black {
        color: #000;
        border: none;
        font-weight: bold;
      }

      .red {
        color: #FF3200;
        border: none;
        font-weight: bold;
      }
    }
  }

  .select-point-modal {
    position: relative;
    padding: 30px;
    text-align: center;

    .close-btn {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 16px;
      height: 16px;
      background: url("../images/close-modal.png") no-repeat center/contain;
      border-radius: 50%;

      i {
        font-size: 14px;
      }
    }

    h3 {
      font-size: 16px;
      margin-bottom: 28px;
    }

    .point-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;

      .check-icon {
        width: 20px;
        height: 20px;
        margin-right: 15px;
        border: 2px solid #FF3201;
        border-radius: 50%;
        background-color: #fff;

        &.active {
          background: url("../images/tick-2.png") no-repeat center/contain;
        }
      }

      .rule {
        font-size: 14px;
        color: #000;
      }
    }

    .confirm-btn {
      width: 100%;
      height: 36px;
      margin-top: 40px;
      font-size: 13px;
      line-height: 36px;
      font-weight: bold;
      color: #fff;
      background-color: #000;
      cursor: pointer;
    }
  }
  .in-checkout-nav {
    background: white;
    .add_Address &:before {
      .IN-iconfont("\e6f7");        color: #666666;
    }
    > .icon-arrow-back {
      opacity: 0;
      pointer-events: none;
    }
    a {
      color: #666666;
    }
    h2 {
      color: #333333;
      font-size: 1.8rem;
    }
  }
  .in-checkout-process {
    background: white;
    box-shadow: 0 0.05rem 0.7rem 0 rgba(0, 0, 0, 0.06);
    padding: 2.1rem 0 1.5rem;
    position: relative;
    z-index: 2;
    &:before {
      margin-bottom: -0.9rem;
    }
    ul li {
      i {
        background: white;
        border-width: 0.3rem;
        height: 1.8rem;
        width: 1.8rem;
      }
      p {
        font-size: 1.1rem;
        margin-top: 0.9rem;
      }
    }
  }
  .in-checkout-totals {
    padding: 0;
    dl {
      padding: 0.5rem 0;
      dd {
        padding: 0;
        p {
          color: #666666;
          font-size: 1.1rem;
          padding: 0;
          text-align: right;
        }
      }
      dt {
        color: #333333;
        font-size: 1.2rem;
        padding: 0;
        text-align: left;
      }
    }
  }
  .in-coupon-code {
    background: white;
    border-radius: 0.3rem;
    box-shadow: 0 0.05rem 0.7rem 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 0.8rem;
    .in-coupon-container {
      font-size: 1.3rem;
      height: auto;
      padding: 1.2rem 0 0.4rem;
      .in-input {
        flex: 1 1 45%;
        input {
          font-size: 1.2rem;
          height: 2.7rem;
          line-height: 2.7rem;
        }
      }
      .in-submit {
        flex: 1 1 55%;
      }
    }
    h2 {
      color: #aaaaaa;
      font-size: 1rem;
      height: 2.9rem;
      line-height: 2.9rem;
      margin: 0;
      text-align: left;
    }
  }
  .in-input-row {
    .icon-arrow-down &:before {
      .IN-iconfont("\e707");        color: #979797;        font-size: 1.4rem;
    }
    label {
      color: #bbbbbb;
    }
  }
  .in-item-container {
    margin-bottom: 0.8rem;
    padding-bottom: 0 !important;
    .in-item-qty {
      color: #666666;
      font-size: 1.3rem;
    }
    .in-item-subtotal {
      color: #666666;
    }
    .in-title {
      border-bottom: 1px solid gainsboro;
      color: #aaaaaa;
      font-size: 1rem;
      height: 2.9rem;
      line-height: 2.9rem;
      text-align: left;
    }
    > div {
      background: white;
      border-radius: 0.3rem;
      ul li:last-child {
        border: none;
      }
    }
  }
  .in-payment-method {
    background: white;
    border-radius: 0.3rem;
    box-shadow: 0 0.05rem 0.7rem 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 0.8rem;
    .in-payments-container {
      padding: 1.2rem 0;
      .in-payment-item {
        padding: 0;
        &.active dd:not(.payment-desc) &:before {
          color: @c-orange;
        }
        &:before {
          display: none;
        }
        .payment-desc {
          font-size: 1.1rem;
        }
        dd:not(.payment-desc) {
          align-items: center;
          display: flex;
          &:before {
            .IN-iconfont("\e719");            width: 3.5rem;
          }
        }
        dt {
          font-size: 1.2rem;
          margin-top: 0.8rem;
        }
      }
      .in-payment-item.active dd:not(.payment-desc):before {
        color: #000;
      }
    }
    h2 {
      border-bottom: 1px solid gainsboro;
      color: #aaaaaa;
      font-size: 1rem;
      height: 2.9rem;
      line-height: 2.9rem;
      margin: 0;
      text-align: left;
    }
  }
  .in-shipping-expenses {
    background: white;
    border-radius: 0.3rem;
    box-shadow: 0 0.05rem 0.7rem 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 0.8rem;
    & + p {
      color: #999999;
      font-size: 1.1rem;
      line-height: 1.5;
      margin-bottom: 3rem;
    }
    &.step2 {
      margin-bottom: 3rem;
    }
    h2 {
      border-bottom: 1px solid gainsboro;
      color: #aaaaaa;
      font-size: 1rem;
      height: 2.9rem;
      line-height: 2.9rem;
      margin: 0;
      text-align: left;
    }
    h3.in-Montreal {
      display: none;
    }
  }
  .in-shipping-info {
    background: white;
    border-radius: 0.3rem;
    box-shadow: 0 0.05rem 0.7rem 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 0.8rem;
    .in-shipinfo-container {
      .icon-user {
        flex-shrink: 0;
        &:before {
          .IN-iconfont("\e6c8");          color: #666666;
        }
      }
      > div {
        .in-tel {
          color: #999999;
        }
        .item_address {
          font-size: 1.2rem;
          margin-bottom: 0.4rem;
        }
        .item_name {
          font-size: 1.3rem;
          padding: 0.4rem 0;
        }
      }
    }
    h2 {
      border-bottom: 1px solid gainsboro;
      color: #aaaaaa;
      font-size: 1rem;
      height: 2.9rem;
      line-height: 2.9rem;
      margin: 0;
      text-align: left;
    }
  }
  .in-shipping-method .in-method-container {
    .icon-cart {
      display: block;
      flex-shrink: 0;
      width: 4rem;
      &:before {
        .IN-iconfont("\e6c9");          color: #666666;
      }
    }
    > div {
      .in-tel {
        color: #999999;
      }
      .item_address {
        font-size: 1.2rem;
        margin-bottom: 0.4rem;
      }
      .item_name {
        font-size: 1.3rem;
        padding: 0.4rem 0;
      }
    }
  }
}
