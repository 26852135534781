.in-choose-attrs {
  .margin-rem(20px 0);
  .in-label {
    .font-size-rem(20px);
    .margin-bottom-rem(20px);
    font-weight: bold;
    text-transform: uppercase;
  }
  .in-out-of-stock {
    a {
      color: #b0b0b0;
    }
  }
  a {
    .centered-line(2.8rem);
    .inline-block;
    .padding-rem(0 26px);
    background: @border-color;
    border-radius: 1.5rem;
    color: @font-color;
    &.active {
      background: #fff;
      color: #ff3200;
      border: 1px solid #ff3200;
    }
  }
  li {
    .margin-rem(0 14px 14px 0);
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
}
.in-choose-city,.in-choose-state {
  .padding-top-rem(5);
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & {
    i {
      color: #999;
      line-height: 23px;
    }
    span {
      display: block;
      width: 100%;
    }
  }
}
.in-choose-country {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transform: translateX(100%);
  & {
    i {
      color: #999;
      line-height: 23px;
    }
    span {
      display: block;
      width: 100%;
    }
  }
  .in-choose-country-text {
    align-items: center;
    display: flex;
    img {
      margin-right: 16px;
      width: 2.4rem;
    }
  }
}
.in-choose-time {
  .centered-line(3.7em);
  .font-size-em(14px);
  .margin-rem(20px 0);
  .padding-rem(0 26px);
  background: @border-color;
  border-radius: 30px;
  border: 1px solid @input-border-color;
  display: flex;
  justify-content: space-between;
  i {
    color: @dark-border;
  }
  span {
    .font-size-rem(18px);
    flex: 1;
    text-align: center;
  }
}
