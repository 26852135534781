.modal {
  &__container {
    min-height: 70vh;
    padding-bottom: 80px;
    padding: 1.5rem;
  }
  &__footer {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 100;
  }
  &__head {
    align-items: center;
    display: flex;
    justify-content: space-between;
    h6 {
      font-size: 1.4rem;
    }
  }
}
.modal-fixed {
  background: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  .form-item {
    margin-bottom: 12px;
  }
  .in-details-container {
    margin: 0 !important;
  }
  .in-header-nav h2 {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
  .in-input-row {
    border-radius: 1.5rem;
    font-size: 1.4rem;
    height: 3rem;
    line-height: 3rem;
  }
  .in-rma-details-container {
    position: initial;
  }
  .modal__header .in-account-nav h2 + a {
    opacity: 0!important;
    pointer-events: none!important;
  }
  .modal_body {
    height: 100%;
    overflow-y: scroll;
    padding: 102px 12px 82px;
  }
  label {
    color: #666666;
    display: block;
    font-size: 24px;
    margin-bottom: 12px;
  }
  select {
    background: #eee;
    border: 1px solid #e7e7e7;
    padding: 0 .8rem;
  }
}
.modal-order {
  background: #f9f9f9;
}
