html {
  direction: rtl;
}

.in-ads-product-content {
  >ul {
    >li:nth-child(odd) {
      margin-right: 0;
    }
    >li:nth-child(even) {
      margin-right: .4rem;
    }
  }
}

.in-home-newsletter {
  direction: ltr;
  form {
    direction: rtl;
  }
}

.in-search-container {
  .in-navbar-form {
    margin-right: 0;
    margin-left: 2rem;
  }
}

.catalog-product {
  .in-product-actions {
    .wishlist {
      img {
        width: 2rem;
      }
    }
  }
}

.v--modal-overlay {
  direction: ltr;
  .pick-up__result {
    direction: rtl;
  }
}

// .in-bottom-nav {
//   a {
//     .active {
//       color: #fff;
//       background: #000;
//     }
//   }
// }
.v--modal .in-label {
  text-align: right;
}
.in-choose-attrs ul {
  flex-direction: row-reverse;
}
.in-product-qty {
  flex-direction: row-reverse;
}

.icon-arrow-back {
  transform: rotate( -180deg);
  display: block;
}
.ship-to-title .icon-arrow-back {
  transform: rotate(0);
}
.in-item-info .icon-arrow-back, .in-review-title .icon-arrow-back {
  transform: rotate(0) !important;
}

.xp-navbar-container {
  .xp-navbar-left-content,.xp-navbar-right-content {
    transform: rotate( -180deg);
  }
}

// pp shop
.pp-inventory__item div.time-ticker-container {
  left: 55px;
  right: auto;
}

.in-detail-container div.time-ticker-container {
  left: auto;
  right: 16px;
}

.pp-order-ship .button-group .in-btn {
  padding: 6px 10px;
}

.account-sales-order-view .grp-txt {
  padding: 10px 6px;
}

.account-sales-order-view .logistic-label i {
  float: left;
}

// home
.in-ads-title a {
  right: auto;
  left: 10px;
}

.in-cms-index .in-ads-container .in-ads-title h2 {
  text-align: right;
}

.in-product-reviews-wrapper .in-rating-wrapper form {
  direction: rtl;
}

.catalog-product .in-rating-wrapper .in-form .close_icon {
  left: 1.3rem;
  right: unset;
}

//timer
.in-cms-index {
  .in-timer-content ul {
    li:last-child {
      padding-left: 0;
      padding-right: .4rem;
    }
    li:first-child {
      padding-right: 0;
      padding-left: .4rem;
    }

    li:first-child::after {
      content:'';
    }
    li{
      &:last-child::after {
        display: block;
      }
    }
  }
}

.in-cms-index .in-timer-content.in-timer-center {
  z-index: 1;
}

.checkout-index .in-checkout-totals dl {
  dt {
    text-align: right;
  }
  dd p {
    text-align: left;
  }
}

.xclubEvaluation:before {
  top: -19px;
  height: 33px;
  width: 156px;
}

.in-menu-wrapper ul li:last-child {
  margin-right: 0;
}

.in-order-tab ul li:last-child {
  border-right: 1px solid #ccc;
}

.in-product-reviews-wrapper .icon-arrow-back {
  transform: unset;
}

.share-modal {
  .v-modal {
    right: 12%;
  }
}
